<template>
  <v-autocomplete
    v-model="model"
    :items="countries"
    item-text="name"
    item-value="alpha-2"
    :label="(multiple ? $tc('common.labels.country', 2) : $tc('common.labels.country', 1))"
    :multiple="multiple"
    :chips="multiple"
    :search-input.sync="search"
    return-object
    :clearable="enableClearable"
    outlined
    @input="onInput">
  </v-autocomplete>
</template>

<script>
  // Mixins
  import countriesData from '@/translations/constants/countries_list.json'

  export default {
    name: "SelectCountries",

    props: {
      multiple: {type: Boolean, default: false},
      enableClearable: {type: Boolean, default: false},
      value: {type: [Array, String, Object], default: () => []},
      isRequired: {type: Boolean, default: true}
    },

    mixins: [],

    data() {
      return {
        countries: countriesData,
        model: [],
        search: null
      }
    },

    created() {
      this.model = this.value;
    },

    methods: {
      onInput(value) {
        this.$emit('input', value ? value['alpha-2'] : null);
        this.search = "";
      }
    }

  }
</script>

<style scoped>

</style>
